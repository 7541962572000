/* mod smartpanel icons */
.mod-panel-icon {
	[role="widget"] {
		.btn-panel {
			font-family: 'Font Awesome 6 Pro';
	    	font-weight: 300;
			-webkit-font-smoothing: antialiased;
			display: inline-block;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			line-height: 1;
			color: inherit;
			background: none !important;

			position: relative;
			height: $panel-btn-icon-height;
			width: $panel-btn-icon-width;
			font-size: $panel-btn-icon-font-size;
			opacity: 0.7;
			display: inline-flex;
			align-items: center;
			margin: 0;
			border-radius: $btn-border-radius;
			text-align: center;
			transition: none;

			&:hover {
				opacity: 1;
			}

			&:active {
				font-size: $panel-btn-icon-font-size - 0.10rem;
			}
		

			&.js-panel-collapse:before,
			&.js-panel-fullscreen:before,
			&.js-panel-close:before {
				background: transparent;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				border-radius: 0;
				transition: none;			
			}

			&.js-panel-collapse:before {
				content: "\f068";
			}

			&.js-panel-fullscreen:before {
				content: "\f065";
			}

			&.js-panel-close:before {
				content: "\f00d";
			}
		}

		&.panel-collapsed {
			.js-panel-collapse:before {
				content: "\f067" !important;
			}
		}

		&.panel-fullscreen {
			.js-panel-fullscreen {
				font-size: 1.5rem;
				&:before {
					content: "\f066";
				}
			}
		}
	}	
}